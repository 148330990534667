import React from 'react'
import translate from '../../../../modules/translate/index'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary/index'
import MessageError from '../../../Error/screens/MessageError/index'
import ListObituary from '../../components/ListObituary'
import SkeletonListObituary from '../../components/ListObituary/skeleton'
import PropTypes from 'prop-types'

const errorOptions = {
  component: translate('obituaries_error'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'Obituary screen display',
}

const ScreenObituary = ({ response: { data }, error, loading, ready, ...props }) => (
  <ErrorBoundary {...errorOptions} {...props}>
    {error && <MessageError logInfo={error.message} {...errorOptions} />}
    {loading && <SkeletonListObituary />}
    {data && <ListObituary data={data} />}
  </ErrorBoundary>
)

ScreenObituary.propTypes = {
  response: PropTypes.shape({
    meta: PropTypes.object,
    data: ListObituary.propTypes.data,
  }),
  loading: PropTypes.bool,
  error: PropTypes.object,
  ready: PropTypes.bool,
  match: PropTypes.object,
}

ScreenObituary.defaultProps = {
  response: {
    data: undefined,
  },
}

export default ScreenObituary
