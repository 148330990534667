import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconMessageTraffic from './components/IconMessageTraffic'
import TextMessageTraffic from './components/TextMessageTraffic'
import TitleMessageTraffic from './components/TitleMessageTraffic'
import TextDate from './components/TextDate'
import styledContainerQuery from 'styled-container-query'

const Wrapper = styled.div`
  padding: 1em ${props => props.theme.sizes.gapEdge};
  box-sizing: border-box;
  @media (max-width: ${props => props.theme.tablet.breakPoint + 'px'}) {
    padding: 1em ${props => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${props => props.theme.mobile.breakPoint + 'px'}) {
    padding: 1em ${props => props.theme.mobile.sizes.gapEdge};
  }
`

const StyledIconMessageTraffic = styled(IconMessageTraffic)``

const StyledContainerArticle = styledContainerQuery.div`
  display: flex;
  width: ${props => props.theme.sizes.col8};
  margin: auto;
  
  @media (max-width: 1080px) {
    width: 100%;
  }
  
  &:container(max-width: 480px) {
    ${StyledIconMessageTraffic} {
      width: 50px;
      height: 50px;
    }
  }
`

const Content = styled.div`
  width: 100%;
`

const Image = styled.div`
  padding-right: ${props => props.theme.sizes.gapCol};
  @media (max-width: ${props => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${props => props.theme.tablet.sizes.gapCol};
  }
  @media (max-width: ${props => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${props => props.theme.mobile.sizes.gapCol};
  }
`

const Date = styled(TextDate)`
  margin-top: 0.5em;
`

const MessageTraffic = ({ iconName, iconDescription, title, children, start, end, zone, type, ...props }) => (
  <Wrapper {...props}>
    <StyledContainerArticle>
      <Image>
        <StyledIconMessageTraffic title={iconDescription} alt={iconDescription} name={iconName} />
      </Image>
      <Content>
        <TitleMessageTraffic>{title}</TitleMessageTraffic>
        <TextMessageTraffic>{children}</TextMessageTraffic>
        <Date start={start} end={end} />
      </Content>
    </StyledContainerArticle>
  </Wrapper>
)

MessageTraffic.propTypes = {
  iconName: PropTypes.string,
  iconDescription: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  children: PropTypes.node,
}

MessageTraffic.defaultProps = {
  end: null,
  iconName: null,
}

export default MessageTraffic
export {
  Wrapper as MessageTraffic0Wrapper,
  StyledContainerArticle as MessageTraffic0StyledContainerArticle,
  Image as MessageTraffic0Image,
  StyledIconMessageTraffic as MessageTraffic0IconMessageTraffic,
  Content as MessageTraffic0Content,
}
