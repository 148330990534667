import React from 'react'
import PropTypes from 'prop-types'
import MessageError from '../../../Error/screens/MessageError'
import translate from '../../../../modules/translate'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import OverviewTraffic from '../../components/OverviewTraffic'
import SkeletonOverviewTraffic from '../../components/OverviewTraffic/skeleton'

const errorOptions = {
  component: translate('traffic'),
  logError: 'ScreenTraffic screen display',
}

const ScreenTraffic = ({ response: { data }, loading, error, ready, match, ...props }) => (
  <ErrorBoundary {...errorOptions} {...props}>
    {error && <MessageError logInfo={error.message} {...errorOptions} />}
    {loading && <SkeletonOverviewTraffic />}
    {data && <OverviewTraffic data={data} />}
  </ErrorBoundary>
)

ScreenTraffic.propTypes = {
  response: PropTypes.shape({
    meta: PropTypes.object,
    data: OverviewTraffic.propTypes.data,
  }),
  loading: PropTypes.bool,
  error: PropTypes.object,
  ready: PropTypes.bool,
  match: PropTypes.object,
}

ScreenTraffic.defaultProps = {
  response: {
    data: undefined,
  },
}

export default ScreenTraffic
