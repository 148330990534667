import React from 'react'
import PropTypes from 'prop-types'
import translate from '../../../../modules/translate'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../Error/screens/MessageError'
import SkeletonListFaq from '../../components/ListFaq/skeleton'
import ListFaq from '../../components/ListFaq'
import isServerSide from '../../../../modules/is-server-side'

const errorOptions = {
  component: translate('topic'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'Faq screen display',
}

const ScreenFaq = ({ response: { data }, loading, error, ready, match, location, ...props }) => {
  const pushToErrorScreen = () => {
    props.history.push({ pathname: '/p/error', state: { location: isoLocation } })
  }

  let isoLocation = location ? location : !isServerSide() && window.location
  return (
    <ErrorBoundary
      error={error}
      onError={(error) => {
        pushToErrorScreen(error)
      }}
      {...errorOptions}
    >
      {error && <MessageError logInfo={error.message} {...errorOptions} />}
      {loading && <SkeletonListFaq title={'FAQ'} />}
      {data && <ListFaq data={data} disableTabs />}
    </ErrorBoundary>
  )
}

ScreenFaq.propTypes = {
  response: PropTypes.shape({
    meta: PropTypes.object,
    data: PropTypes.object,
  }),
  loading: PropTypes.bool,
  error: PropTypes.object,
  ready: PropTypes.bool,
  match: PropTypes.object,
}

ScreenFaq.defaultProps = {
  response: {
    data: undefined,
  },
}

export default ScreenFaq
