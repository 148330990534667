import React from 'react'
import moment from 'moment'
import 'moment/locale/de'
import PropTypes from 'prop-types'
import translate from '../../../../../../../../../../modules/translate'
import TextMessageTraffic from '../TextMessageTraffic'

const DATE_FORMAT = 'L'

const TextDate = ({ start, end, ...props }) => {
  let string = translate('traffic_message_date_not_known')
  const startFormatted = moment(start)
    .locale('de')
    .format(DATE_FORMAT)
  const endFormatted = moment(end)
    .locale('de')
    .format(DATE_FORMAT)
  if (moment(start).isValid() && moment(start).diff(moment()) > 0 && moment(end).isValid()) {
    string = translate('traffic_message_date_from_to', { start: startFormatted, end: endFormatted })
  } else if (moment(end).isValid()) {
    string = translate('traffic_message_date_to', { end: endFormatted })
  } else if (moment(start).isValid() && moment(start).diff(moment()) > 0) {
    string = translate('traffic_message_date_from', { start: startFormatted })
  }

  return <TextMessageTraffic {...props}>{string}</TextMessageTraffic>
}

TextDate.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
}

TextDate.defaultProps = {
  start: null,
  end: null,
}

export default TextDate
