import React from 'react'
import styled, { withTheme } from 'styled-components'
import TitleTopic from '../../../../components/TitleTopic'
import ItemArticle from '../../../Article/components/ItemArticle'
import PropTypes from 'prop-types'
import ContainerFull from '../../../../components/ContainerFull'
import Collapse from './images/faq-collapse.svg'
import Expand from './images/faq-expand.svg'
import RowLocation from '../../../Weather/components/LiveData/components/RowLocation'
import isServerSide from '../../../../modules/is-server-side'

const Wrapper = styled(ContainerFull)`
  font-family: ${(props) => props.theme.font.primary};
`
const NiceToggle = styled.p`
  margin: unset;
  margin-left: auto;
  height: 1.5em;
`

const NiceImage = styled.img`
  height: 1.5em;
`

const Akkordeon = styled.div`
  background-color: #eee;
  color: #444;
  cursor: pointer;
  font-weight: bold;
  width: 90%;
  padding: 1rem;
  font-size: 20px;
  margin: 1rem auto;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  display: flex;
  &:hover {
    background-color: #ccc;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin: unset;
    width: unset;
    margin-top: 10px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin: unset;
    width: unset;
    margin-top: 10px;
  }
`

const AkkordeonQuestion = styled.div`
  color: #444;
  cursor: pointer;
  font-weight: bold;

  padding: 1rem;
  font-size: 20px;
  margin: 1rem auto;
  margin-right: unset;
  text-align: left;
  display: flex;
  border-bottom: 1px solid rgba(180, 180, 180, 0.5);
  color: rgba(120, 120, 120, 0.9);
  outline: none;
  transition: 0.4s;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin: unset;
    width: unset;
    margin-top: 10px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin: unset;
    width: unset;
    margin-top: 10px;
  }
`

const NiceDiv = styled.div``
const Panel = styled.div`
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  color: rgba(140, 140, 140, 0.8);
`
const PanelQuestion = styled.div`
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  width: 90%;
  margin: 1rem auto;
  font-size: 20px;
`
const HtmlWrapper = styled(ContainerFull)`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.text};
  color: ${(props) => props.theme.color.textPrimary};
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const ListFaq = ({ data: { title, articles, html, backgroundColor }, disableTabs, ...props }) => {
  function parseFaq(str) {
    str = str
      .replace(/<[\/]?(span|font|div)[^>]*>/g, ' ')

      .replace(/<br>( )<\//gm, '</')
      .replace(/<\/b>( )/gm, '</b>&nbsp;')
      .replace(/<\/h4><h4>/gm, '<br><br>')
      .replace(/<\/h2><h2>/gm, '')
      .replace(/<\/h3><h3>/gm, '')

      // .replace(/<\/a>/gm,' ')

      .replace(/((?:\\[rn]|\r|\n)+|(?: ){2,})/gm, ' ')

      .replace(/>(?: )+/gm, '>')
      .replace(/<a[^>]* href="([^"]+)"[^>]*>[^>]*<\/a>&nbsp;/gm, '$1 ')
      .replace(/<a[^>]* href="([^"]+)"[^>]*>[^>]*<\/a>/gm, '$1 ')

      .replace(/(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*)/gim, '<a target="_blank" href="$1">$1</a>')

    let reg_h2 = /<h2>(.*?)<\/h2>/gms
    let reg_h3 = /<h3>(.*?)<\/h3>/gms
    let reg_h4 = /<h4>(.*?)<\/h4>/gms

    let h2 = [...str.matchAll(reg_h2)]
    let h3 = [...str.matchAll(reg_h3)]
    let h4 = [...str.matchAll(reg_h4)]

    let h2s = []

    let j = 0,
      k = 0

    for (let i = 0; i < h2.length; i++) {
      let i_limit = i + 1 < h2.length ? h2[i + 1].index : -1

      let h3s = []

      for (; j < h3.length && (i_limit === -1 || h3[j].index < i_limit); j++) {
        h3s.push({
          title: h3[j][1],
          content: k < h4.length ? h4[k++][1] : null,
        })
      }

      h2s.push({
        title: h2[i][1],
        sub: h3s,
      })
    }

    return h2s
  }

  let h2akkordeon = parseFaq(html)

  function toggle(title, keepOpened) {
    if (!isServerSide()) {
      var akk = document.getElementById(title)

      var panel = akk.nextElementSibling

      if (panel.style.maxHeight && !keepOpened) {
        panel.style.maxHeight = null
        akk.firstElementChild.firstElementChild.src = Expand
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px'
        akk.firstElementChild.firstElementChild.src = Collapse
      }
    }
  }

  function toggleQuestion(title, content, keepOpened) {
    if (!isServerSide()) {
      var akk = document.getElementById(title)

      var panel = akk.nextElementSibling
      var panelbefore = akk.parentElement.parentElement

      if (panel.style.maxHeight && !keepOpened) {
        panel.firstElementChild.innerHTML = null
        panel.style.maxHeight = null
        akk.firstElementChild.firstElementChild.src = Expand
      } else {
        panel.firstElementChild.innerHTML = content
        panel.style.maxHeight = panel.scrollHeight + 'px'
        panelbefore.style.maxHeight = panelbefore.scrollHeight + panel.scrollHeight + 'px'
        akk.firstElementChild.firstElementChild.src = Collapse
      }
    }
  }

  function jumpToQuestion(selectedStation) {
    toggle(selectedStation.value.topicTitle, true)
    toggleQuestion(selectedStation.label, selectedStation.value.questionContent, true)
    let panel = document.getElementById(selectedStation.label)
    const yOffset = -200
    const y = panel.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
  const options = []

  h2akkordeon.forEach(function (com) {
    let topicTitle = com.title
    com.sub.forEach(function (question) {
      let option = { value: { topicTitle: topicTitle, questionContent: question.content }, label: question.title }

      options.push(option)
    })
  })

  return (
    <Wrapper noGapEdge cinemaMode={backgroundColor && { backgroundPrimary: backgroundColor }} {...props}>
      <TitleTopic view="screen" title={title} />
      <RowLocation values={options} placeholder={'select_questions'} onChange={jumpToQuestion} />

      {h2akkordeon.map((faqTopic) => {
        return (
          <NiceDiv>
            <Akkordeon id={faqTopic.title} onClick={() => toggle(faqTopic.title)}>
              {faqTopic.title.replace('<br>', '').replace('&amp;', '&')}{' '}
              <NiceToggle>
                <NiceImage src={Expand}></NiceImage>
              </NiceToggle>{' '}
            </Akkordeon>

            <PanelQuestion>
              {faqTopic.sub.map((subTopic) => {
                return (
                  <NiceDiv>
                    <AkkordeonQuestion
                      id={subTopic.title}
                      onClick={() => toggleQuestion(subTopic.title, subTopic.content)}
                    >
                      {subTopic.title.replace('<br>', '').replace('&amp;', '&').replace('&nbsp;', '')}{' '}
                      <NiceToggle>
                        <NiceImage src={Expand}></NiceImage>
                      </NiceToggle>
                    </AkkordeonQuestion>
                    <Panel>
                      <p></p>
                    </Panel>
                  </NiceDiv>
                )
              })}
            </PanelQuestion>
          </NiceDiv>
        )
      })}
    </Wrapper>
  )
}

ListFaq.propTypes = {
  data: PropTypes.shape({
    ...TitleTopic.propTypes,
    articles: PropTypes.arrayOf(ItemArticle.propTypes),
    backgroundColor: PropTypes.string,
    html: PropTypes.string,
  }),
  disableTabs: PropTypes.bool,
}

export default withTheme(ListFaq)
