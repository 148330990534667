import React from 'react'
import moment from 'moment'
import 'moment/locale/de'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TitleDescriptionPage from '../../../../../../components/TitleDescriptionPage'
import translate from '../../../../../../modules/translate'

const StyledTitleDescriptionPage = styled(TitleDescriptionPage)`
  padding-top: 0.5em;
  height: 1.2em;
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const TitleDescriptionLastActivity = ({ children, ...props }) => {
  let lastActivity = translate('traffic_last_activity', {
    date: moment(children).locale('de').format('LLL'),
  })

  if (!moment(children, moment.ISO_8601).isValid()) {
    lastActivity = ''
  }

  return <StyledTitleDescriptionPage {...props}>{lastActivity}</StyledTitleDescriptionPage>
}

TitleDescriptionLastActivity.propTypes = {
  children: PropTypes.string,
}

export default TitleDescriptionLastActivity
