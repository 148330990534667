import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import image from './images'
import errorLog from '../../../../../../../../../../modules/error-log'

const Icon = styled.img`
  display: block;
  width: 70px;
  height: 70px;
`

const EmptyIcon = styled(Icon)``

const IconMessageTraffic = ({ name, ...props }) => {
  if (name in image) {
    if (!image[name]) {
      errorLog({ error: 'Missing Icon!', info: 'Traffic Icon' })
    }
    return <Icon src={image[name]} {...props} />
  }

  return <EmptyIcon as="div" {...props} />
}

IconMessageTraffic.propTypes = {
  name: PropTypes.string,
}

IconMessageTraffic.defaultProps = {
  name: null,
}

export default IconMessageTraffic
