import achtungImage from './achtung.svg'
import ampelImage from './ampelregelung.svg'
import baustelleImage from './baustelle.svg'
import brandgefahrImage from './brandgefahr.svg'
import bushaltestelleImage from './bushaltestelle.svg'
import fahrverbotImage from './fahrverbot.svg'
import freiImage from './frei.svg'
import gegenverkehrImage from './gegenverkehr.svg'
import haustierImage from './haustier.svg'
import kreisverkehrImage from './kreisverkehr.svg'
import nebelImage from './nebel.svg'
import radarkontrolleImage from './radarkontrolle.svg'
import rundrufImage from './rundruf.svg'
import schleudergefahrImage from './schleudergefahr.svg'
import schneefallImage from './schneefall.svg'
import seitenwindImage from './seitenwind.svg'
import stauImage from './stau.svg'
import steinschlagImage from './steinschlag.svg'
import unfallImage from './unfall.svg'
import verbotNutzfahrzeugeImage from './verbot_nutzfahrzeuge.svg'
import verengteFahrbahnImage from './verengte_fahrbahn.svg'
import wildtierImage from './wildtier.svg'
import winterausruestungImage from './winterausruestung.svg'
import fahrradwegImage from './fahrradweg.svg'
import eisenbahnImage from './eisenbahn.svg'
import LkwFahrverbot from './lkw-fahrverbot.svg'
import UmgefallenerBaum from './umgefallener_baum.svg'

export default {
  achtung: achtungImage,
  ampelregelung: ampelImage,
  baustelle: baustelleImage,
  brandgefahr: brandgefahrImage,
  bushaltestelle: bushaltestelleImage,
  fahrradweg: fahrradwegImage,
  fahrverbot: fahrverbotImage,
  frei: freiImage,
  gegenverkehr: gegenverkehrImage,
  haustier: haustierImage,
  kreisverkehr: kreisverkehrImage,
  nebel: nebelImage,
  radar: radarkontrolleImage,
  rundruf: rundrufImage,
  schleudergefahr: schleudergefahrImage,
  schneefall: schneefallImage,
  seitenwind: seitenwindImage,
  stau: stauImage,
  steinschlag: steinschlagImage,
  unfall: unfallImage,
  verbot_nutzfahrzeuge: verbotNutzfahrzeugeImage,
  verengte_fahrbahn: verengteFahrbahnImage,
  wildtier: wildtierImage,
  winterausruestung: winterausruestungImage,
  eisenbahn: eisenbahnImage,
  lkwFahrverbot: LkwFahrverbot,
  baum: UmgefallenerBaum,
}

/* TODO here SHOULD be require.context */
