import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TextSkeleton from '../../../../components/Text/views/TextSkeleton'

import skeletonFade from '../../../../modules/mixins/skeleton-fade'

const Wrapper = styled.div``

const TextDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  width: 70%;
`
const ObitDiv = styled.div`
  margin-top: 150px;
`
const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`

const ImageDiv = styled.div`
  ${skeletonFade};
  width: 127px;
  height: 160px;
  margin-left: auto;
  margin-right: auto;
  background-color: lightgray;
`
const StyledHr = styled.hr`
  ${skeletonFade};
  height: 3px;
  border: unset;
  background-color: lightgray;
`
const Obit = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 22%;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 40%;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 100%;
  }
`
const StyledTextSkeleton = styled(TextSkeleton)`
  margin-top: 1.7em;
  margin-bottom: 1.5em;
  margin-left: 19px;
  height: 20px;
`
const SkeletonListObituary = ({ ...props }) => (
  <Wrapper {...props}>
    <TextDiv>
      <StyledTextSkeleton lastWidth="80%"></StyledTextSkeleton>
      <StyledTextSkeleton lastWidth="90%"></StyledTextSkeleton>
      <StyledTextSkeleton lastWidth="100%"></StyledTextSkeleton>
    </TextDiv>
    <ObitDiv>
      <StyledTextSkeleton lastWidth="50px"></StyledTextSkeleton>

      <FlexDiv>
        <Obit>
          <ImageDiv />
          <StyledHr />
          <br />
          <StyledHr />
          <br />
          <StyledHr />
          <br />
          <StyledHr />
        </Obit>
        <Obit>
          <ImageDiv />
          <StyledHr />
          <br />
          <StyledHr />
          <br />
          <StyledHr />
          <br />
          <StyledHr />
        </Obit>
        <Obit>
          <ImageDiv />
          <StyledHr />
          <br />
          <StyledHr />
          <br />
          <StyledHr />
          <br />
          <StyledHr />
        </Obit>
        <Obit>
          <ImageDiv />
          <StyledHr />
          <br />
          <StyledHr />
          <br />
          <StyledHr />
          <br />
          <StyledHr />
        </Obit>
      </FlexDiv>
    </ObitDiv>
  </Wrapper>
)
SkeletonListObituary.propTypes = {}
SkeletonListObituary.defaultProps = {}
export default SkeletonListObituary
