import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styledContainerQuery from 'styled-container-query'
import ItemObituary from '../components/ItemObituary/'
import ContainerAd from '../../../components/ContainerAd'
import ContainerFull from '../../../components/ContainerFull'
import Date from '../../../components/Date'

const StyledDate = styled(Date)`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  flex-basis: 100%;
  padding-bottom: ${(props) => props.theme.sizes.gapColHalf};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-bottom: ${(props) => props.theme.tablet.sizes.gapColHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.sizes.gapEdge};
    padding-right: ${(props) => props.theme.sizes.gapEdge};
    padding-bottom: ${(props) => props.theme.mobile.sizes.gapColHalf};
  }
`

const StyledItemObituary = styled(ItemObituary)`
  width: ${(props) => props.theme.sizes.col3};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col3};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col3};
  }
`

const StyledContainerAd = styled(ContainerAd)`
  flex-basis: 100%;
`

const Wrapper = styledContainerQuery(ContainerFull)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: calc(2*${(props) => props.theme.sizes.gapEdge});
  @media(max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}){
    padding-bottom: calc(2*${(props) => props.theme.tablet.sizes.gapEdge});
  }
  @media(max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}){
    padding-bottom: calc(2*${(props) => props.theme.mobile.sizes.gapEdge});
  }
  ${(props) =>
    props.noGapEdge &&
    css`
      padding-bottom: 0;
    `}
  &:container(max-width: 800px) {
    ${StyledItemObituary} {
      width: calc(${(props) => props.theme.sizes.col4} + 90px);
  }
  &:container(max-width: 560px) {
    ${StyledItemObituary} {
      width: 100%;
  }
`

const WidgetObituary = ({ obituariesOfOneDay, adIds, noGapEdge, ...props }) => (
  <Wrapper noGapEdge={noGapEdge} {...props}>
    {obituariesOfOneDay.deathDay && <StyledDate format="D.MM.YYYY">{obituariesOfOneDay.deathDay}</StyledDate>}
    {
      // Associated ObituaryItems
      obituariesOfOneDay.associatedObituaries.map((obituaryItem, index) => {
        return <StyledItemObituary key={'ItemObituary' + index} {...obituaryItem} />
      })
    }
    {adIds && <StyledContainerAd adIds={adIds} />}
  </Wrapper>
)

WidgetObituary.propTypes = {
  deathDay: PropTypes.string.isRequired,
}

WidgetObituary.defaultProps = {
  noGapEdge: false,
}

export default WidgetObituary
