import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  RowSelect0OverviewTraffic,
  StyledContainerArticle0OverviewTraffic,
  TitleDescriptionLastActivity0OverviewTraffic,
  TitlePage0OverviewTraffic,
} from './index'
import translate from '../../../../modules/translate'
import trafficDisabilityTypes from '../../../../config/data/trafficDisabilityTypes'
import southTyrolDistrictTrafficZones from '../../../../config/data/southTyrolDistrictTrafficZones'
import MessageTrafficSkeleton from './_components/TrafficMessages/components/MessageTraffic/views/MessageTrafficSkeleton'

const SkeletonOverviewTraffic = ({ ...props }) => (
  <Fragment>
    <StyledContainerArticle0OverviewTraffic {...props}>
      <TitlePage0OverviewTraffic>{translate('traffic_header')}</TitlePage0OverviewTraffic>
      <TitleDescriptionLastActivity0OverviewTraffic>&nbsp;</TitleDescriptionLastActivity0OverviewTraffic>
      <RowSelect0OverviewTraffic types={trafficDisabilityTypes.all} zones={southTyrolDistrictTrafficZones.all} />
    </StyledContainerArticle0OverviewTraffic>
    <div>
      <MessageTrafficSkeleton />
      <MessageTrafficSkeleton />
      <MessageTrafficSkeleton />
      <MessageTrafficSkeleton />
      <MessageTrafficSkeleton />
    </div>
  </Fragment>
)

SkeletonOverviewTraffic.propTypes = {}

SkeletonOverviewTraffic.defaultProps = {}

export default SkeletonOverviewTraffic
