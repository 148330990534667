import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Text from '../../../../components/Text'
import Date from '../../../../components/Date'
import Image from '../../../../components/Image'

const StyledText = styled(Text)`
  color: ${(props) => props.theme.color.textSecondary};
  display: flex;
  text-decoration: none;
`
const StyledDeathText = styled(Text)`
  color: ${(props) => props.theme.color.textSecondary};
  display: flex;
`
const StyledBirthText = styled(Text)`
  color: ${(props) => props.theme.color.textSecondary};
  display: flex;
`
const StyledDate = styled(Date)`
  color: ${(props) => props.theme.color.textSecondary};
  display: inline;
`

const Border = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.color.textTertiary};
  height: ${(props) => props.borderHeight};
  margin-top: 5px;
  margin-bottom: 5px;
`

const Wrapper = styled.a`
  ${(props) =>
    props.noPadding
      ? css`
          padding-left: 0;
          padding-right: 0;
          padding-top: calc(${(props) => props.theme.sizes.gapVertical} / 2);
          padding-bottom: calc(${(props) => props.theme.sizes.gapVertical} / 2);
          @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
            padding-top: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
            padding-bottom: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
          }
          @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
            padding-top: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
            padding-bottom: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
          }
        `
      : css`
          padding: 15px;
        `};
  text-decoration: none;
  &:hover{
    cursor: pointer;
    background-color: ${(props) => props.theme.color.backgroundBorder};
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
    transition: box-shadow 0.5s ease-in-out;
    transition: background-color 0.5s ease-in-out;
    ${StyledText}{
      color: #000;
      transition: color 0.2s ease-in-out;
    }
    ${StyledBirthText}{
      color: #000;
      transition: color 0.2s ease-in-out;
    }
    ${StyledDeathText}{
      color: #000;
      transition: color 0.2s ease-in-out;
    }
    ${StyledDate}{
      color: #000;
      transition: color 0.2s ease-in-out;
    }
    ${Border}{
      background-color: #000;
      transition: background-color 0.2s ease-in-out;
    }
`

const StyledImage = styled(Image)`
  display: block;
  margin: 0 auto 12.5px;
  height: 160px;
  width: 126px;
`

const First = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  color: #000;
`

const Last = styled.div`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.base};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.base};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.base};
  }
  color: #000;
`

const NameWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`

const CommunityOfDeathWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`

const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  ${(props) =>
    props.birthDay === null &&
    css`
      justify-content: center;
    `}
`

const ItemObituary = ({
  firstName,
  lastName,
  birthDay,
  deathDay,
  cityOfBirth,
  communityOfDeath,
  urlTrauerportal,
  image,
  noPadding,
  ...props
}) => (
  <Wrapper {...props} target="_blank" href={urlTrauerportal} noPadding={noPadding}>
    <StyledImage src={image} />
    <Border borderHeight="3px" />
    <NameWrapper>
      <First>{firstName}</First> &nbsp;
      <Last>{lastName}</Last>
    </NameWrapper>
    <Border borderHeight="1px" />
    {communityOfDeath && (
      <Fragment>
        <CommunityOfDeathWrapper>
          <StyledText>{communityOfDeath}</StyledText>
        </CommunityOfDeathWrapper>
        <Border borderHeight="1px" />
      </Fragment>
    )}
    <DetailsWrapper birthDay={birthDay}>
      {birthDay && (
        <StyledBirthText>
          *&nbsp;<StyledDate format="D.MM.YYYY">{birthDay}</StyledDate>
        </StyledBirthText>
      )}
      <StyledDeathText>
        †&nbsp;<StyledDate format="D.MM.YYYY">{deathDay}</StyledDate>
      </StyledDeathText>
    </DetailsWrapper>
    <Border borderHeight="3px" />
  </Wrapper>
)

ItemObituary.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  birthDay: PropTypes.string.isRequired,
  deathDay: PropTypes.string.isRequired,
  borderHeight: PropTypes.oneOf(['low', 'high']),
  image: PropTypes.string.isRequired,
  noPadding: PropTypes.bool,
}

ItemObituary.defaultProps = {
  noPadding: false,
}

export default ItemObituary
