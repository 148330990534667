import styled from 'styled-components'
import PropTypes from 'prop-types'
import Title from '../../../../../../../../../../components/Title'

const TitleMessageTraffic = styled(Title)`
  margin-top: 0;
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
`

TitleMessageTraffic.propTypes = {
  children: PropTypes.node,
}

export default TitleMessageTraffic
