import React from 'react'
import {
  MessageTraffic0Content,
  MessageTraffic0IconMessageTraffic,
  MessageTraffic0Image,
  MessageTraffic0StyledContainerArticle,
  MessageTraffic0Wrapper,
} from '../../index'
import styled from 'styled-components'
import TextSkeleton from '../../../../../../../../../../components/Text/views/TextSkeleton'
import { skeletonFade } from '../../../../../../../../../../modules/mixins'

const StyledMessageTraffic0IconMessageTraffic = styled(MessageTraffic0IconMessageTraffic)`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  ${skeletonFade};
`

const MessageTrafficSkeleton = ({ ...props }) => (
  <MessageTraffic0Wrapper {...props}>
    <MessageTraffic0StyledContainerArticle>
      <MessageTraffic0Image>
        <StyledMessageTraffic0IconMessageTraffic />
      </MessageTraffic0Image>
      <MessageTraffic0Content>
        <TextSkeleton lines={1} lastWidth="50%" />
        <TextSkeleton lines={3} lastWidth="30%" />
      </MessageTraffic0Content>
    </MessageTraffic0StyledContainerArticle>
  </MessageTraffic0Wrapper>
)

export default MessageTrafficSkeleton
