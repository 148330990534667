import styled from 'styled-components'
import Text from '../../../../../../../../../../components/Text'
import PropTypes from 'prop-types'

const TextMessageTraffic = styled(Text)`
  color: ${(props) => props.theme.color.textSecondary};
  @media (max-width: 330px) {
    font-size: 12px;
  }
`

TextMessageTraffic.propTypes = {
  children: PropTypes.node,
}

export default TextMessageTraffic
