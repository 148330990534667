import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ContainerArticle from '../../../../components/ContainerArticle'
import Text from '../../../../components/Text'
import TitlePage from '../../../../components/TitlePage'
import translate from '../../../../modules/translate'
import TitleDescriptionLastActivity from './_components/TitleDescriptionLastActivity'
import RowSelect from './_components/RowSelect'
import TrafficMessages from './_components/TrafficMessages'
import moment from 'moment'

const StyledContainerArticle = styled(ContainerArticle)`
  padding-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.tablet.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.mobile.sizes.gapVertical};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const StyledText = styled(Text)`
  margin: 0 auto;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: 30vh;
  font-size: ${(props) => props.theme.fontSize.title2};
  margin-left: ${(props) => props.theme.sizes.gapEdge};
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title2};
    margin-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    font-size: ${(props) => props.theme.mobile.fontSize.title2};
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  text-align: center;
`

const OverviewTraffic = ({ data, ...props }) => {
  const [messages, setMessages] = useState(data.messages)
  const [selectedType, setSelectedType] = useState(0)
  const [selectedZone, setSelectedZone] = useState(0)

  const filterZone = (zone) => {
    setSelectedZone(zone)
    filter(selectedType, zone)
  }

  const filterType = (type) => {
    setSelectedType(type)
    filter(type, selectedZone)
  }

  const filter = (type, zone) => {
    let filteredMessages = data.messages.filter((message) => {
      let typeCompareHelper = type === 0 ? message.type : type
      let zoneCompareHelper = zone === 0 ? message.zoneId : zone
      if (typeCompareHelper === message.type && zoneCompareHelper === message.zoneId) {
        return message
      }
      return null
    })
    setMessages(filteredMessages)
  }

  let syncTime = data && data.syncTime ? data.syncTime : moment().format()

  let convertedZones = []
  let convertedTypes = []
  convertedTypes.push({ label: translate('all') + ' ' + translate('types'), value: 0 })
  convertedZones.push({ label: translate('all') + ' ' + translate('zones'), value: 0 })
  data &&
    Object.keys(data.zones).map((zone) => {
      convertedZones.push({ label: data.zones[zone], value: Number(zone) })
      return null
    })
  data &&
    Object.keys(data.types).map((type) => {
      convertedTypes.push({ label: data.types[type], value: Number(type) })
      return null
    })
  //
  return (
    <React.Fragment {...props}>
      <StyledContainerArticle>
        <TitlePage>{translate('traffic_header')}</TitlePage>
        <TitleDescriptionLastActivity>{syncTime}</TitleDescriptionLastActivity>
        <RowSelect
          updateType={(type) => filterType(Number(type))}
          updateZone={(zone) => filterZone(Number(zone))}
          zones={convertedZones}
          types={convertedTypes}
          defaultType={data.defaultType}
        />
      </StyledContainerArticle>
      <TrafficMessages messages={messages} />
      {messages && messages.length <= 0 && data && (
        <StyledText tag="h2">{translate('traffic_selection_no_data')}</StyledText>
      )}
    </React.Fragment>
  )
}

OverviewTraffic.propTypes = {
  data: PropTypes.shape({
    types: PropTypes.object,
    zones: PropTypes.object,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        long: PropTypes.string,
        short: PropTypes.string,
        type: PropTypes.number,
        level: PropTypes.number,
        begin: PropTypes.string,
        end: PropTypes.string,
        street: PropTypes.string,
        code: PropTypes.string,
        subCode: PropTypes.string,
        zoneId: PropTypes.number,
      }),
    ),
    syncTime: PropTypes.string,
  }),
}

export default OverviewTraffic
export {
  StyledContainerArticle as StyledContainerArticle0OverviewTraffic,
  TitlePage as TitlePage0OverviewTraffic,
  TitleDescriptionLastActivity as TitleDescriptionLastActivity0OverviewTraffic,
  RowSelect as RowSelect0OverviewTraffic,
}
