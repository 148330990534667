import React from 'react'
import styled, { css } from 'styled-components'
import ContainerFull from '../../../../../../components/ContainerFull'
import MessageTraffic from './components/MessageTraffic'
import { createUuid } from '../../../../../../modules/uuid'
import ContainerAd from '../../../../../../components/ContainerAd'
//import MessageTrafficSkeleton from './components/MessageTraffic/views/MessageTrafficSkeleton'

const StyledMessageTraffic = styled(MessageTraffic)`
  ${(props) =>
    props.index % 2 === 0 &&
    css`
      background-color: $;
    `}
`
//const StyledMessageTrafficSkeleton = styled(MessageTrafficSkeleton)``

const Wrapper = styled(ContainerFull)`
  padding-left: 0;
  padding-right: 0;
`

/*
  ${StyledMessageTraffic}:nth-child(2n - 1),
  ${StyledMessageTrafficSkeleton}:nth-child(2n - 1) {
    background-color: ${props => props.theme.color.background};
  }
*/

const StyledContainerAd = styled(ContainerAd)`
  padding: 0;
  justify-content: space-evenly;
  > div > div {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const TrafficMessages = ({ messages, ...props }) => {
  return (
    <Wrapper {...props}>
      {messages &&
        messages.map(({ subCode, short, title, long, start, end, id }, index) => {
          let iconName
          let customSubCode = subCode
          if (long && (long.includes('umgestürzter Bäume') || long.includes('umgestürzte Bäume'))) {
            customSubCode = 'baum'
          }
          switch (customSubCode) {
            case 'sperre':
              if (title === 'Radwegenetz') {
                iconName = 'fahrradweg'
              } else {
                iconName = 'fahrverbot'
              }
              break
            case 'veranstaltung':
              iconName = 'achtung'
              break
            case 'beschraenkung':
              iconName = 'achtung'
              break
            case 'vorsicht':
              iconName = 'achtung'
              break
            case 'frei-befahrbar':
              iconName = 'frei'
              break
            case 'kolonnenverkehr':
              iconName = 'stau'
              break
            case 'lkw-fahrverbot':
              iconName = 'lkwFahrverbot'
              break
            case 'mure':
              iconName = 'steinschlag'
              break
            case 'viehabtrieb':
              iconName = 'haustier'
              break
            default:
              iconName = customSubCode
          }
          if (index === 4) {
            return (
              <div key={createUuid()}>
                <StyledContainerAd adIds={['S3', 'S4']} />
                <StyledMessageTraffic
                  iconName={iconName}
                  iconDescription={short}
                  title={title}
                  start={start}
                  end={end}
                  key={id}
                  index={index}
                  loading={false}
                >
                  {long}
                </StyledMessageTraffic>
              </div>
            )
          }
          return (
            <div key={createUuid()}>
              <StyledMessageTraffic
                iconName={iconName}
                iconDescription={short}
                title={title}
                start={start}
                end={end}
                key={id}
                index={index}
                loading={false}
              >
                {long}
              </StyledMessageTraffic>
            </div>
          )
        })}
    </Wrapper>
  )
}

TrafficMessages.defaultProps = {
  messages: [],
}

export default TrafficMessages
